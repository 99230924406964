














































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import MembersTable from '@/components/members/MembersTable.vue'
import AddMemberForm from '@/components/members/AddMemberForm.vue'
import Drawer from '@/components/common/Drawer.vue'
import DialogInfo from '@/components/dialogs/DialogInfo.vue'
import Info from '@/components/common/Info.vue'
import EllipseCommon from '@/components/common/EllipseCommon.vue'
import FilterTags from '@/components/common/FilterTags.vue'
import { MembersListItemType } from '@/types/members'
import {
  TableExportType,
  TableFilterType,
  TableOrderType,
} from '@/types/general'
import SelectCommon from '@/components/common/SelectCommon.vue'
import MembersCard from '@/components/mobile/members/MembersCard.vue'
import MembersFilters from '@/components/mobile/members/MembersFilters.vue'
import MembersDetail from '@/components/members/MembersDetail.vue'
import { Message } from 'element-ui'
import { ShopsListItemType } from '@/types/shops'
import ShopInfo from '@/components/shops/ShopInfo.vue'

@Component({
  components: {
    MembersDetail,
    MembersFilters,
    MembersCard,
    SelectCommon,
    FilterTags,
    EllipseCommon,
    AddMemberForm,
    DialogInfo,
    Info,
    MembersTable,
    Drawer,
    ShopInfo,
  },
})
export default class Members extends Vue {
  loading = false
  loadingInfo = false
  isAnimationEnd = false

  page = 1

  filter = 'Все'
  filters: TableFilterType[] = []

  order: TableOrderType | Record<string, any> = {}

  showMemberInfo = false
  showShopInfo = false

  addMemberDialog = false
  addMemberInfo = false

  filterNames = {
    UF_REMOTE_USER_LOGIN: 'Логин',
    SHOP_NAME: 'Магазин',
    PHONE: 'Номер телефона',
    EMAIL: 'Эл.почта',
    ACTIVE_BALANCE: 'Баллы',
  }

  filterOptions = [
    {
      label: 'Все',
      value: 'Все',
    },
    {
      label: 'Топ 10',
      value: '10',
    },
    {
      label: 'Топ 100',
      value: '100',
    },
  ]

  showFilters = false
  showMembersFilters = false
  query = {
    isTop: 0,
    topLimit: 10,
  }
  params = {
    filter: {},
    order: {},
    offset: 0,
  }

  members = [] as MembersListItemType[]

  get shopsDetail(): ShopsListItemType {
    return this.$store.getters['shops/shopsDetail']
  }

  get membersData(): MembersListItemType[] {
    return this.$store.getters['members/membersList']
  }

  //TODO приходит не вся информация об участнике
  get membersDetail(): MembersListItemType {
    return this.$store.getters['members/membersDetail']
  }

  get membersTotal(): string {
    return this.$store.getters['members/membersTotal']
  }

  get membersExport(): TableExportType {
    return this.$store.getters['members/exportMembers']
  }

  get showedMembersCount() {
    if (this.membersTotal) {
      return 10 * this.page > parseInt(this.membersTotal)
        ? this.membersTotal
        : 10 * this.page
    } else {
      return 0
    }
  }

  get tableFilters() {
    const params = {}

    this.filters.forEach((data) => {
      params[data.target] = data.data
    })

    return params
  }

  get tableOrder() {
    const params = {}

    if (this.order && this.order.order) {
      params[this.order.prop] =
        this.order.order === 'descending' ? 'DESC' : 'ASC'
    }

    return params
  }

  get currentFilter() {
    return this.filterOptions.find((filter) => filter.label === this.filter)
  }

  lazyDownload(): void {
    const link = document.createElement('a')

    link.setAttribute(
      'href',
      process.env.VUE_APP_BASE_URI + this.membersExport.link
    )
    link.setAttribute('download', this.membersExport.name)
    link.click()
    link.remove()
  }

  handleChangeSort(data: TableOrderType): void {
    this.order = data
    this.handleChangePage()
  }

  handleOpenDialog(): void {
    this.addMemberDialog = !this.addMemberDialog
  }

  handleAddMember(): void {
    this.addMemberDialog = false
    this.addMemberInfo = true
  }

  handleCloseShopInfo(): void {
    this.showShopInfo = false
  }

  handleDeleteFilter(index: number): void {
    this.filters.splice(index, 1)
  }

  handleAddTableFilter(data: TableFilterType): void {
    const foundFilterIndex = this.filters.findIndex(
      (filter) => filter.target === data.target
    )

    if (foundFilterIndex === -1) {
      if (data.data === '') {
        return
      }

      this.filters.push({
        data: data.data,
        target: data.target,
        name: this.filterNames[data.target],
      })
    } else {
      if (data.data === '') {
        this.handleDeleteFilter(foundFilterIndex)
      }

      this.filters.forEach((item) => {
        if (item.target === data.target) {
          this.handleDeleteFilter(foundFilterIndex)

          this.filters.push({
            data: data.data,
            target: data.target,
            name: this.filterNames[data.target],
          })
        }
      })
    }
    this.page = 1
  }

  handleShowFilters(): void {
    this.showFilters = !this.showFilters
  }

  async handleShopInfoShow(
    id: ShopsListItemType['UF_REMOTE_SHOP_ID']
  ): Promise<void> {
    this.showMemberInfo = false
    this.showShopInfo = true

    await this.$store.dispatch('shops/getShopsDetail', id)
  }

  handleShowMembersFilters(): void {
    this.showMembersFilters = !this.showMembersFilters
  }

  handleSelectFilter(option: string): void {
    this.filter = option
    this.handleShowFilters()
  }

  handleSelectFilters(data: TableFilterType[]): void {
    this.handleShowMembersFilters()

    data.forEach((value) => {
      this.handleAddTableFilter(value)
    })

    setTimeout(() => {
      this.isAnimationEnd = true
    }, 200)
  }

  async handleChangePage(): Promise<void> {
    const tabs = document.querySelector('.members__filter') as HTMLElement

    tabs.scrollIntoView({ behavior: 'smooth' })

    this.params.filter = this.tableFilters
    this.params.order = this.tableOrder
    this.params.offset = (this.page - 1) * 10

    if (this.currentFilter?.value) {
      this.query.isTop = 1
      this.query.topLimit = +this.currentFilter.value
    }

    if (this.currentFilter?.value && isNaN(+this.currentFilter?.value)) {
      this.query.isTop = 0
      this.query.topLimit = 10
    }

    this.loading = true

    await this.$store.dispatch('members/getMembersList', {
      params: this.params,
      order: this.tableOrder,
      query: this.query,
    })

    this.members = this.membersData

    this.loading = false
  }

  async handleShowMemberInfo(id: number): Promise<void> {
    this.showShopInfo = false
    this.loadingInfo = true
    this.showMemberInfo = true
    await this.$store.dispatch('members/getMembersDetail', id)
    this.loadingInfo = false
  }

  async handleExportMembers() {
    await this.$store.dispatch('members/getExportMembers', {
      filter: this.tableFilters,
      order: this.tableOrder,
      query: this.query,
    })

    if (
      this.membersExport.type === 'error' ||
      this.membersExport.type === 'queue'
    ) {
      Message.error(this.membersExport.text || 'Ошибка')
    } else {
      this.lazyDownload()
    }
  }

  @Watch('filters')
  async onFiltersChange(): Promise<void> {
    this.loading = true

    this.params.filter = this.tableFilters
    this.params.order = this.tableOrder
    this.params.offset = (this.page - 1) * 10

    await this.$store.dispatch('members/getMembersList', {
      params: this.params,
      order: this.tableOrder,
      query: this.query,
    })

    this.members = this.membersData

    this.loading = false
  }

  @Watch('page')
  onPageChange(): void {
    this.params = {
      filter: {},
      order: {},
      offset: 0,
    }

    this.query = {
      isTop: 0,
      topLimit: 10,
    }

    this.handleChangePage()
  }

  @Watch('filter')
  async onFilterChange(): Promise<void> {
    this.params = {
      filter: {},
      order: {},
      offset: 0,
    }

    this.query = {
      isTop: 0,
      topLimit: 10,
    }

    this.loading = true

    this.page = 1

    await this.handleChangePage()

    this.loading = false
  }

  async mounted(): Promise<void> {
    this.loading = true

    await this.$store.dispatch('members/getMembersList')

    this.members = this.membersData

    this.loading = false
  }

  //TODO Нету добавления участника
  //TODO Отключить доступ
}
