
































import { Component, Vue, Prop } from 'vue-property-decorator'
import { MembersListItemType } from '@/types/members'

@Component
export default class MembersCard extends Vue {
  @Prop({ type: Object })
  member!: MembersListItemType

  handleShowMemberInfo(): void {
    this.$emit('show-member', this.member.id)
  }
}
