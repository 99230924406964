



































































































import { Component, Vue, Model } from 'vue-property-decorator'
import { Upload } from 'element-ui'
import InputCommon from '@/components/common/InputCommon.vue'
import { usersAPI } from '@/api/users.api'
import { Message } from 'element-ui'
import { AddUserForm, NewMember } from '@/types/users'

@Component({
  components: {
    InputCommon,
  },
})
export default class AddMemberForm extends Vue {
  @Model('change', { type: Boolean })
  value!: boolean

  addMemberForm: NewMember = {
    lastName: '',
    firstName: '',
    phoneNumber: '',
    email: '',
    photo: null,
  }

  $refs!: {
    upload: Upload
  }

  get uploadedPhotos(): string[] {
    return this.$store.getters['files/uploadedFiles']
  }

  uploadPhoto(file): void {
    if (file.raw.type.slice(0, 5) === 'image') {
      this.$store.dispatch('files/uploadFiles', file.raw)
      this.addMemberForm.photo = file
    } else {
      this.$refs.upload.clearFiles()
    }
  }

  clearPhoto(): void {
    this.$refs.upload.clearFiles()
    this.addMemberForm.photo = null
  }

  async handleAddMember(): Promise<void> {
    const payload: AddUserForm = {
      PHONE: this.addMemberForm.phoneNumber,
      EMAIL: this.addMemberForm.email,
      NAME: this.addMemberForm.firstName,
      LAST_NAME: this.addMemberForm.lastName,
      PERSONAL_PHOTO: this.uploadedPhotos[0],
    }

    const [error, data] = await usersAPI.addNewUser(payload)

    if (!error && data) {
      this.$emit('add-member')
    } else {
      Message({
        type: 'error',
        message: 'Произошла ошибка',
      })
    }
  }

  handleCloseDialog(): void {
    this.$emit('change', false)
  }
}
